import React, { useState } from 'react';
import SlideOne from './Slides/SlideOne/SlideOne';
import SlideTwo from './Slides/SlideTwo/SlideTwo';


function Slideshow() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [<SlideOne />];

  const handleWheel = (e) => {
    if (e.deltaY > 0 && currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else if (e.deltaY < 0 && currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return (
    <div onWheel={handleWheel}>
      {slides.map((slide, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            opacity: currentSlide === index ? 1 : 0,
            transition: 'opacity 0.5s ease-out'
          }}
        >
          {slide}
        </div>
      ))}
    </div>
  );
}

export default Slideshow;