import React from 'react';
import './SlideOne.css';
import AppleMusicLogo from '../SlideTwo/AppleMusicLogo.png';
import SpotifyLogo from '../SlideTwo/spotifyIcon.png';
import ScrollDownLogo from '../SlideOne/scrollDown.png';
import YouTubeLogo from '../SlideOne/YouTubeLogo.png';
import InstagramLogo from '../SlideOne/instagramLogo.png';

function redirectToURL(url) {
  console.log('triggered');
  window.location.href = url;
}

function SlideOne() {
  // Generate an array of 50 circles with random sizes, positions, and delays
  const circles = Array.from({ length: 25 }).map((_, i) => {
    const size = Math.random() * 50; // Random size between 0 and 50
    const left = Math.random() * 100; // Random position between 0 and 100
    const delay = Math.random() * 5; // Random delay between 0 and 5 seconds
    return <div key={i} className="circle" style={{ width: `${size}px`, height: `${size}px`, left: `${left}vw`, animationDelay: `${delay}s` }} />;
  });

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h1 className='title'> ⓔⓣⓗⓔⓡⓓⓡⓔⓐⓜⓢⓢ </h1>
      <div className='platform-icons'>
        <img className='music-logo' src={AppleMusicLogo} onClick={() => redirectToURL("https://music.apple.com/us/artist/etherdreamss/1689918320")}></img>
        <img className='music-logo-two' src={SpotifyLogo} onClick={() => redirectToURL("https://open.spotify.com/artist/05t5IZeofcaT3REETSkH6x")}></img>
        <img className='music-logo-three' src={YouTubeLogo} onClick={() => redirectToURL("https://www.youtube.com/@etherdreamss")}></img>
        <img className='music-logo-three' src={InstagramLogo} onClick={() => redirectToURL("https://www.instagram.com/etherdreamss/")}></img>
      </div>
      
      {circles}

      
      
    </div>
  );
}

export default SlideOne;
