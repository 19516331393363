
import './App.css';
import Slideshow from './SlideShow';
function App() {
  return (
    <div className="App">
      <Slideshow />
    </div>
  );
}

export default App;
